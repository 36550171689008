import './App.css';

function App(){
    return(
        <div className="App">
            <header className="App-header">
                <a href='https://inmedia.pe' className='logo'>
                    <img src="https://inmedia.pe/Componentes/Header/Imagenes/logo_nav.png" alt="Logo de Inmedia Marketing Web"></img>
                </a>

                <p>Menú</p>
            </header>
        </div>
    );
}

export default App;
